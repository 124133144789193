import React from 'react'
import useStyles from './Style'
import Image from 'next/image'
import { Grid, Typography, Box } from '@mui/material'
import Tag from 'src/components/shared/atoms/Tag/Tag'
import { ButtonComponent } from 'src/components/shared/atoms'
import { ImageInterface } from 'src/types/store/index'
import { StaticImageData } from 'next/image'
import { useRouter } from 'next/router'
// import algoliasearch from 'algoliasearch/lite'

interface Props {
  id: number
  title: string
  desc: string
  tags: {
    tagName: string
    link: string
  }[]
  img: ImageInterface
  imgBg: StaticImageData
  button: {
    content: string
    href: string
  }
  zenMode: boolean
  partners?: boolean
}
function MentalHealth({
  id,
  title,
  desc,
  tags,
  img,
  imgBg,
  zenMode,
  button,
  partners,
}: Props) {
  const { classes } = useStyles()
  const router = useRouter()
  const [articleCount, setArticleCount] = React.useState<number>(0)
  const [researchCount, setResearchCount] = React.useState<number>(0)

  // const client = algoliasearch(
  //   process.env.NEXT_PUBLIC_ALGOLIA_APP || '',
  //   process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
  // )
  // const searchIndex = client.initIndex('search_articles')
  // const searchResearchPaperIndex = client.initIndex('search_research_paper')
  // searchIndex.search(title.toUpperCase()).then(({ nbHits }) => {
  //   setArticleCount(nbHits)
  // })

  // searchResearchPaperIndex.search(title).then(({ nbHits }) => {
  //   setResearchCount(nbHits)
  // })
  const stringModifier = (str: string) => {
    const words = str.split(' ')
    const modifiedWords = words.map((word, index) => {
      if (index === words.length - 1) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
      return word.charAt(0).toUpperCase() + word.slice(1) + '-'
    })
    const modifiedStr = modifiedWords.join('')
    return modifiedStr
  }

  const countSelector = (tag: string) => {
    if (tag) {
      return tag
    }
    if (tag == 'Articles') {
      setArticleCount(0)
      setResearchCount(0)
      return `${articleCount} ${tag}`
    } else if (tag.toLowerCase() == 'research papers') {
      if (researchCount == 0) return ''
      else if (researchCount == 1) return tag
      else return `${researchCount} ${tag}`
    } else {
      return tag
    }
  }

  const urlSelector = (tag: string) => {
    if (tag == 'Articles' || tag == 'Artículos') {
      if (title == 'mental health') {
        router.push(`/${'Mental-Health-Support-Hub'}`)
      } else {
        router.push(`/${stringModifier(title) + '-Support-Hub'}`)
      }
    } else if (tag.toLowerCase() == 'research papers') {
      router.push('/research-report')
    } else if (tag.toLowerCase().includes('relaxing videos')) {
      router.push('/dtl-originals')
    } else if (tag.toLowerCase().includes('interviews')) {
      router.push('/campaign')
    }
  }

  return (
    <div className={classes.outerBox} key={id}>
      <div className={classes.InnerBox}>
        <div
          className={`${classes.cardWrapper} ${zenMode && classes.zenMode}`}
          style={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {!zenMode && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
              }}
            >
              <Image src={imgBg?.src} alt={'background'} fill />
            </div>
          )}

          <Grid
            container
            p={{ xs: 5, sm: 5, md: 5, lg: 5 }}
            pb={3.3}
            pl={{ xs: 5, sm: 5, md: 10, lg: 13 }}
            justifyContent="center"
            alignItems="center"
            columnSpacing={{ xs: 0, sm: 0, md: 10, lg: 14 }}
            flexDirection={{
              lg: 'row',
              md: 'row',
              sm: 'row',
              xs: 'column-reverse',
            }}
            aria-label={`${title} card`}
          >
            <Grid item xs={12} md={8} sm={8} lg={8}>
              <Box style={{ position: 'relative' }}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={`${classes.desc}`}>{desc}</Typography>
                <div className={classes.tagsWrapper}>
                  {tags?.map((tag, idx) => {
                    return (
                      <Tag
                        id={idx}
                        key={idx}
                        content={countSelector(tag.tagName)}
                        color="black"
                        background="rgba(255, 255, 255, 0.3)"
                        borderColor="none"
                        style={{
                          backdropFilter: 'blur(47px)',
                          whiteSpace: 'nowrap',
                          display:
                            countSelector(tag.tagName) == '' ? 'none' : 'block',
                        }}
                        onClick={() => {
                          urlSelector(tag.tagName)
                        }}
                      />
                    )
                  })}
                </div>
                <div>
                  <ButtonComponent
                    type="text"
                    text={button.content}
                    className={classes.learnMoreBtn}
                    gradient
                    wrapperClass={classes.btnWrapper}
                    onClick={() => {
                      if (partners) {
                        router.push(button.href)
                        return
                      }
                      if (title == 'mental health') {
                        router.push(`/${'Mental-Health-Support-Hub'}`)
                      } else {
                        router.push(
                          `/${stringModifier(title) + '-Support-Hub'}`
                        )
                      }
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sm={4}
              lg={4}
              className={classes.imgWrapper}
              style={{ position: 'relative' }}
            >
              <Image
                src={`${img?.data?.attributes?.url || ''}`}
                alt={`${title} image`}
                width={img?.data?.attributes?.width || 0}
                height={Number(img?.data?.attributes?.height || '')}
                className={zenMode ? classes.zenModeImg : ''}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default MentalHealth
